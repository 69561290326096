<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"
                        >
                            {{ getTendersDetails.contractor.name }}
                        </p>
                        <span
                            ><b>{{ `${$t('tenders:tenderNo')}` }}:</b>
                            {{ $get(getTendersDetails, 'tenderNo', '') }}
                        </span>
                        <span
                            ><b>{{ `${$t('tenders:deadline')}` }}:</b>
                            {{
                                getTendersDetails.deadline
                                    ? $moment(
                                          getTendersDetails.deadline
                                      ).format(`DD/MM/YYYY`)
                                    : $t('global:empty')
                            }}</span
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="5"
                        ><v-text-field
                            readonly
                            :value="$get(getTendersDetails, 'tenderNo', '')"
                            :label="$t('tenders:tenderNo')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4"
                        ><v-text-field
                            readonly
                            :value="
                                getTendersDetails.deadline
                                    ? $moment(
                                          getTendersDetails.deadline
                                      ).format(`DD/MM/YYYY`)
                                    : $t('global:empty')
                            "
                            :label="$t('tenders:deadline')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3"
                        ><v-text-field
                            readonly
                            :value="$get(getTendersDetails, 'time', '')"
                            :label="$t('tenders:time')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="
                                $get(getTendersDetails, 'contractor.name', '')
                            "
                            :label="$t('tenders:contractor')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="findRepresentative(getTendersDetails)"
                            :label="$t('tenders:representative')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="$get(getTendersDetails, 'tenderUrl', '')"
                            :label="$t('tenders:tenderUrl')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-textarea
                            readonly
                            :value="$get(getTendersDetails, 'notes', '')"
                            :label="$t('tenders:notes')"
                            no-resize
                            rows="1"
                            auto-grow
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    computed: {
        ...mapGetters(['getTendersDetails']),
    },
    methods: {
        findRepresentative(item) {
            const id = item.representative
            if (id) {
                const representative = item.contractor?.representatives?.find(
                    e => e._id === id
                )

                return `${this.$get(
                    representative,
                    'name',
                    'Brak'
                )} ${this.$get(representative, 'lastname', '')} ${
                    representative && representative.email ? '•' : ''
                } ${this.$get(representative, `email`, '')} ${
                    representative && representative.phoneNumber ? '•' : ''
                } ${this.$get(representative, 'phoneNumber', '')}`
            }
            return ''
        },
    },
}
</script>
